.inactiveStage {
    height: 240px !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px !important;
}
.inactiveStageTitle {
    font-weight: normal !important;
    color: #c7c7c7 !important

}
.vert-slide-margins {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.for-num-line {
    margin-top: 64px !important;
}
.for-num-line-row::before {
    content: " ";
    border-bottom: 2px solid #4b4b4b;
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 32px;
    z-index: -1;
}
.slideShadow {
    width: 64px;
    top: 7%;
    right: -32px;
    height: 100%;
    position: absolute;
    background: linear-gradient(90deg, #111111 0%, rgba(17, 17, 17, 0) 100%);
    z-index: 1;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}
/* .for-num-line-row::after {
    content: " ";
    border-bottom: 5px solid #4b4b4b
} */
.my-custom-class
{
    --width: 400px;
    --height: 200px;
}
.popoverGridClass
{
    height: 175px;
}
.blur-j-stage {
    position: absolute;
    width: 100vw;
    height: 312px;
    left: 0px;
    /* right: 0px; */
    top: 32px;
    background: transparent;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    z-index: 2;
    margin-left: -32px;
    /* margin-right: 0px; */

}
@media (min-width:1025px) and (-webkit-max-device-pixel-ratio: 1.75) {
    .for-num-line-row::before {
        z-index: 0;
        bottom: 28px;
    }
    .js-slides-back,
    .js-slides {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
    .slideShadow {
        right: 0px !important;
    }
}