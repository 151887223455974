.tfo-calendar-picker-input{
    background-color: #222222;
    cursor:pointer;
    width:250px;
    border: none;
    height:40px;
}
.picker-wrapper.sc-ion-picker-ios{
    top: -102px;
}
@media only screen and (min-width: 1200px) {
.rc-calendar-picker{
    position: relative !important;
    z-index: 1000;
    height: 100vh;
    left: 114px !important;
    top: 334px !important;
}
}
.rc-calendar-panel, .rc-calendar-month-panel{
    background: #1A1A1A 90%!important;
    padding:5px !important;
    color:rgba(170, 170, 170, 1) !important;
}
.rc-calendar-disabled-cell .rc-calendar-date {
    background: #1a1a1a !important;
}

.rc-calendar-header {
    padding: 6px 10px;
    height: 50px;
    line-height: 30px;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    border-bottom: 1px solid #4d4d4d;
    
}

.rc-calendar-month-panel, .rc-calendar-date-panel, .rc-calendar-panel {
    height: 301px;
    width: 315px;
}
.rc-calendar-my-select{
    color:var(--greyscale);
    font-size: 14px!important;
    line-height: 34px!important;
}
.rc-calendar-month-select, .rc-calendar-month-panel-year-select{
    font-size: 14px!important;
    color:var(--greyscale);
}

.rc-calendar-year-select, .rc-calendar-month-panel-year-select, .rc-calendar-month-panel-next-year-btn, .rc-calendar-month-panel-prev-year-btn{
    font-size: 14px!important; 
    color:var(--greyscale);
}
.rc-calendar-column-header-inner{
    color:rgba(170, 170, 170, 1);
}

.rc-calendar-today .rc-calendar-date {
    border: 2px solid #A5D2DC;
    background: #A5D2DC;
    color:rgba(26, 26, 26, 1);
    border-radius: 2px;
    font-weight: 700;
}

.rc-calendar-month-panel-current-cell{
    font-weight: 700;
}

.rc-calendar-month-panel-year-select-content{
    color:var(--greyscale);
    font-size: 14px!important;
    line-height: 34px!important;

}
.rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month {
    background: #A5D2DC;
    color:#1a1a1a;
}


.tfo-calender-pop{
    font-family: var(--ion-font-family);
    font-weight:400;
    font-size: 14px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 1)!important;
    border:none!important;
    border-radius: 8px!important;
}
@media only screen and (min-width: 576px) {
    .rc-calendar-picker{
        position: relative !important;
        z-index: 1000;
        height: 100vh;
        left: 188px !important;
        top: 318px !important;
    }
  }