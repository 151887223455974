/* Chart Fade */
.chartFade {
    position: absolute;
    width: 156px;
    left: 95px;
    top: 0%;
    bottom: 0%;
    background: linear-gradient(90deg, #1A1A1A 0%, rgba(26, 26, 26, 0) 100%);
    margin-top: 15px;
    margin-bottom: 4px;
}
.chartOverlay {
    margin-top: 15px;
    margin-bottom: 4px;
    position: absolute;
    width: 96px;
    left: 0px;
    top: 0%;
    bottom: 0%;
    background: #1A1A1A;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .chartFade {
        width: 105px;
        left: 63px;
    }
    .chartOverlay {
        width: 64px;
    }
}
@media (min-width: 1025px) and (-webkit-max-device-pixel-ratio: 1.75) {
    .chartFade,
    .chartOverlay {
        top: 1% !important;
        margin-bottom: 0px !important;
    }

}
