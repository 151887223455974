.next-prev-cont-upsch2 {
    position: absolute;
    width: 100vw;
    height: 50px;
    left: -180px;
    right: 0;
    /* background-color: red; */
    bottom: 32px;
}
.swiper-button-prev-upsch2 ion-icon,
.swiper-button-next-upsch2 ion-icon {
    width: 36px;
    height: 36px;
}
.swiper-button-prev-upsch2 {
    position: absolute;
    left: 96px !important;
}
.swiper-button-next-upsch2 {
    position: absolute;
    right: 120px !important;
}
.temp-cont {
    position: relative;
}
@media only screen and (min-width: 64.063em) { 
    .slider-upsch2 {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .prof-cal {
        margin-right: -20px !important;
    }
}