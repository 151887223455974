.tab-selected {
  border-top: 3px solid #fff !important;
  --color: var(--ion-color-white);
  --color-selected: var(--ion-color-white);
}
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

/* .tab-selected::before {
background-color: rgba(255, 255, 255, 0.15);
content: '';
height: 27%;
width: 18%;
left: 41%;
top: 24%;
position: absolute;
border-radius: 50%;
transform: rotate(45deg);
box-shadow: 0px 0px 35px #FFF;
} */

.tab-btn {
  border-top: 3px solid transparent;
  position: relative;
  --color: #828282;
}

.tab-selected ion-label {
  color: #fff !important;
}

.tab-selected ion-icon {
  fill: #fff !important;
}

ion-tab-button {
  --color: var(--ion-color-greyscale);
  --color-selected: var(--ion-color-white);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(34, 34, 34) inset !important;
  -webkit-text-fill-color: white !important;
}

.tab-selected ion-icon::after {
  box-shadow: 0 0 50px #fff;
}

ion-tab-bar {
  --background: #222222;
}

ion-item {
  --background: none;
}

ion-app::before {
  content: " ";
  background: url(/assets/Background_Leaf_Image.svg) no-repeat 215px 0px;
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0px;
  z-index: -2;
}

ion-app::after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 300px;
  left: 0px;
  top: 236px;
  background: linear-gradient(180deg, #131313 0%, rgba(17, 17, 17, 0) 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: -1;
}

ion-content {
  /* --background: url(/assets/Background_Leaf_Image.svg) no-repeat 215px 0px ; */
  --background: none !important;
}

/* .tab-bar {
padding-left: 96px !important;
padding-right: 96px !important;
}
.tab-btn {
margin-left: 64px !important;
margin-right: 64px !important;
border-top: 3px solid transparent;
} */

ion-tab-bar {
  height: 60px !important;
}

ion-toolbar {
  height: 75px !important;
}

ion-textarea {
  --placeholder-color: #828282 !important;
}

.my-custom-loading-class {
  --spinner-color: #b99855;
}

/* @media only screen 
and (min-width: 1224px) {
ion-content {
  --padding-start: 160px !important;
  --padding-end: 160px !important;
}

} */
/* ion-content::after {
content: " ";
background-image: url(/assets/Background_Leaf_Image.png) no-repeat 215px 0px;
} */
/* ion-content::after {
background: linear-gradient(180deg, #131313 0%, rgba(17, 17, 17, 0) 100%);
content: " ";
position: absolute;
width: 100%;
height: 300px;
left: 0px;
top: 232px;
transform: matrix(1, 0, 0, -1, 0, 0);
} */
.margintopn10 {
  margin-top: -10px;
}

@media only screen and (min-width: 576px) {
  .login-margin-top {
    margin-top: 160px;
  }

  .card-header-ml-20 {
    margin-left: -20px !important;
  }
}


@media only screen and (max-width: 575px) {
  /*@supports (-webkit-touch-callout: none){*/

  .in-toolbar {
    height: 100px !important;
    padding-top: 13px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .savenotetxt {
    width: 100px;
  }

  .mobile-toolbar-top {
    margin-top: 70px !important;
  }

  .margintopn10 {
    margin-top: 28px !important;
  }

  .btncol-mobile {
    margin-top: -35px !important;
  }

  .font18,
  ion-text,
  ion-button,
  ion-label,
  ion-item,
  input,
  button {
    font-size: 13px !important;
  }

  ion-text {
    white-space: initial;
  }

  .asocAvatar {
    margin: 0px !important;
    width: 40px !important;
    height: 40px !important;
  }

  .assocdata {
    margin-left: 8px;
  }

  ion-grid {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .font18 {
    white-space: inherit !important;
  }

  ion-card-header {
    font-size: 20px !important;
  }

  ion-icon {
    font-size: 14px !important;
  }

  .rc-calendar,
  .rc-calendar-month-panel,
  .rc-calendar-date-panel,
  .rc-calendar-panel {
    width: 240px;
  }

  .mobile-todo-margin {
    margin-right: 0px !important;
  }

  .mobile-todo-daygrid {
    margin-left: -55px;
  }

  .todoCard {
    width: 280px !important;
    margin-top: 20px;
  }

  .timelineVerticalLineNormal {
    margin-left: 0px !important;
    margin-top: 20px !important;
    height: 92% !important;
  }

  .todoMonthItem {
    margin-left: -7px;
  }

  .mobile-moneylabel {
    font-size: 35px !important;
  }

  .mobile-dollar {
    height: 12px !important;
  }

  .mobile-calander-inp {
    max-width: 170px !important;
  }

  .nulltargetrow2,
  .nulltargetrow3 {
    font-size: 30px !important;
  }

  .mobile-reorder-grid {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  ion-reorder {
    margin-left: -10px !important;
  }

  .TargetAvatar {
    margin: 0px !important;
  }

  .TargetAvatar>img {
    width: 30px;
    height: 30px;
    margin-top: 10px;
  }

  .cardTitle,
  .cardTitleGeo {
    font-size: 22px !important;
  }

  .cardTitle {
    margin-left: 25px !important;
  }

  .cardTitle>ion-text {
    margin-left: 4px !important;
  }

  .srchplaceholder {
    margin: 6px !important;
  }

  .mobile-reim-title {
    font-size: 25px !important;
  }

  .my-custom-reinvest-select {
    margin-left: 78px !important;
  }

  .mobile-moneylabel {
    font-size: 35px !important;
  }

  .firstLeft>ion-card-header {
    font-size: 18px !important;
  }

  .firstLeftNr>ion-card-header {
    font-size: 18px !important;
  }

  .mobile-dollar {
    height: 12px !important;
  }

  .mobile-calander-inp {
    max-width: 170px !important;
  }

  .nulltargetrow2,
  .nulltargetrow3 {
    font-size: 30px !important;
  }

  .mobile-reorder-grid {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  ion-reorder {
    margin-left: -10px !important;
  }

  .TargetAvatar {
    margin: 0px !important;
    width: 30px !important;
    height: 30px !important;
  }

  .TargetAvatar>img {
    width: 30px;
    height: 30px;
    margin-top: 0px;
  }

  .TargetBadge {
    padding-left: 8px !important;
    padding-top: 9px !important;
  }

  .AddTargetBadge {
    padding-left: 8px !important;
    padding-top: 7px !important;
  }

  .cardTitle,
  .cardTitleGeo {
    font-size: 22px !important;
  }

  .cardTitle {
    margin-left: 25px !important;
  }

  .cardTitle>ion-text {
    margin-left: 4px !important;
  }

  .srchplaceholder {
    margin: 6px !important;
  }

  .mobile-reim-title {
    font-size: 25px !important;
  }

  .my-custom-reinvest-select {
    margin-left: 78px !important;
  }

  .Row1 {
    white-space: initial !important;
  }

  .login-margin-top {
    margin-top: 100px;
  }

  .dashgrid-mobile {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .slides-mobile {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }

  .button-text-mobile {
    font-size: small !important;
  }

  .align-mobile {
    align-self: center;
  }

  .mobile-header-text {
    font-size: 14px;
    margin-right: 0px !important;
  }

  .mobile-slider-row {
    margin-left: 0px !important;
  }

  .firstLeft-mobile::before {
    border-right: none !important;
  }

  .mobile-padding0 {
    padding: 0px !important;
  }

  .mobile-flex-row {
    flex-direction: row !important;
  }

  .mobile-flex-align {
    align-items: flex-start;
  }

  .rightBorder95 {
    border-left: none !important;
  }

  .mobile-date-postion {
    position: absolute;
    margin-top: 30px !important;
    margin-left: 0px !important;
  }

  .baseaddInfo {
    /*white-space: inherit !important;*/
    display: inline-grid !important;
  }

  .insightsnewmeeting {
    margin-right: -5px;
  }

  .mobile-timeline-title {
    white-space: inherit !important;
  }

  .mobile-todo-title {
    white-space: inherit !important;
    margin-left: 18px !important;
  }

  .mobile-timeline-date {
    font-size: 14px;
  }

  .mobile-todo-margin {
    margin-right: 0px !important;
  }

  .todayToDoNormal {
    margin-left: 0px !important;
    font-size: 8px !important;
    position: absolute;
  }

  .mobile-breakline {
    white-space: inherit !important;
  }

  .rbc-calendar {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .rbc-time-view {
    margin: 0px !important;
  }

  .rbc-header {
    font-size: 10px !important;
  }

  .rbc-event-content {
    font-size: 7px !important;
  }

  .tfo-calendar-item {
    width: 165px !important;
    font-size: 10px !important;
  }

  .tfo-cal-today-btn {
    font-size: 14px !important;
  }

  .calendar-heading {
    font-size: 20px;
  }

  .mobile-nav-btn {
    width: 30px !important;
  }

  .mobile-margin-calander {
    width: 100%;
  }

  .mobile-margin-calander>ion-text {
    font-size: 25px !important;
  }

  .mobile-margin-right {
    margin-right: 32px;
  }

  .action-sheet-selected.sc-ion-action-sheet-ios {
    background: #40412f !important;
    color: white !important;
    font-size: 17px !important;
  }

  .action-sheet-button.sc-ion-action-sheet-ios {
    color: white;
    background: #141414 !important;
  }

  .action-sheet-button-inner.sc-ion-action-sheet-ios {
    font-family: "Gotham";
    font-size: 16px;
  }

  .action-sheet-selected.sc-ion-action-sheet-ios::after {
    background: #40412f !important;
    color: white !important;
  }

  .action-sheet-group.sc-ion-action-sheet-ios:first-child {
    margin-top: 30vh !important;
  }

  .picker-button.sc-ion-picker-ios,
  .picker-button.ion-activated.sc-ion-picker-ios {
    color: #b99855 !important;
  }

  /*}*/
  .ClientDatLastContactCard,
  .ClientDataAUMCard {
    margin-left: 0px !important;
    width: 360px !important;
  }

  .ion-margin-start {
    margin: 0px !important;
  }

  .mobile-toptarget-header {
    white-space: nowrap !important;
  }

  .mobile-toptarget-btn {
    margin-top: -5px !important;
    margin-bottom: 0px !important;
    margin-left: -12px !important;
  }

  .popover-content {
    left: 10px !important;
    width: 354px !important;
  }

  .mobile-geo-margin {
    margin-left: 10px !important;
  }

  .inactiveStage {
    height: 160px !important;
  }

  .mobile-activeStage {
    height: 190px !important;
  }

  .for-num-line {
    margin-top: 30px !important;
  }

  .mobile-for-num-line {
    margin-top: 45px !important;
  }

  .firstLeftEng>ion-card-header {
    font-size: 18px !important;
  }

  .fs-22 {
    font-size: 22px !important;
  }

  .fs-17 {
    font-size: 17px !important;
  }

  .fs-14 {
    font-size: 14px !important;
  }

  .fs-43 {
    font-size: 43px !important;
  }

  .fs-18 {
    font-size: 18px !important;
  }

  .fs-28 {
    font-size: 28px !important;
  }

  .fs-12 {
    font-size: 12px !important;
  }

  .fs-22 {
    font-size: 22px !important;
  }

  .fs-17 {
    font-size: 17px !important;
  }

  .fs-14 {
    font-size: 14px !important;
  }

  .fs-13 {
    font-size: 13px !important;
  }

  .fs-16,
  .TargetIonItem>ion-label>ion-row>ion-text {
    font-size: 16px !important;
  }

  .TargetIonItem>ion-label>ion-row>ion-icon,
  .fs-6 {
    font-size: 6px !important;
  }

  .mfs-14 {
    font-size: 14px !important;
  }

  .mfs-10 {
    font-size: 10px !important;
  }

  .persona-det {
    font-size: 53px !important;
  }

  .insight-grid {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .ion-grid-margin-mobile {
    margin-left: 13px !important;
    margin-right: 18px !important;
  }

  .elipse-mobile {
    font-size: 6px !important;
  }

  .timeline-period {
    font-size: 6px !important;
  }

  .m-marginleft-8 {
    margin-left: 8px;
  }

  .m-marginleft-12 {
    margin-left: 12px !important;
  }

  .m-marginright-8 {
    margin-right: 8px;
  }

  .m-marginleft-4 {
    margin-left: 4px;
  }

  .m-marginright-4 {
    margin-right: 4px;
  }

  .m-marginright-12 {
    margin-right: 12px;
  }

  .m-marginright-0 {
    margin-right: 0px;
  }

  .m-marginrightn4 {
    margin-right: -4px;
  }

  .m-marginleftn4 {
    margin-left: -4px;
  }

  .m-margintop-4 {
    margin-top: 4px !important;
  }

  .m-margintopn16 {
    margin-top: -16px !important;
  }

  .m-marginbottom16 {
    margin-bottom: 16px !important;
  }

  .mobile-btn {
    flex: none !important;
    /* width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%); */
    /* max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%); */
  }

  .mobileML14 {
    margin-left: 14px !important;
  }

  .mobileML36 {
    margin-left: 36px !important;
  }

  .m-marginL-m5 {
    margin-left: -5px !important;
  }

  .firstLeft_cal_mobile {
    padding-left: 20px;
  }

  .js-slides {
    margin-left: -18px !important;
  }

  ion-slide {
    width: unset !important;
    padding-left: 2vw !important;
    padding-right: 2vw !important;
  }

  .mobilenext {
    white-space: nowrap !important;
  }

  .CardGeoMobile {
    width: 100%;
    margin: auto;
    margin-top: 32px;
  }

  .m-card-header-ml-20 {
    margin-left: -20px !important;
  }

  .mobilewidth {
    max-width: 345px !important;
  }
}

@media only screen and (max-width: 768px) {
  .dashgrid-mobile {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

@media (min-width: 1025px) and (-webkit-max-device-pixel-ratio: 1.75) {
  * {
    font-size: 14px;
  }

  .segseltext {
    width: 772 px !important;
    height: 72 px !important;
    left: 30 px !important;
    top: 198 px !important;
  }

  .card-header-ml-20 {
    margin-left: 0px !important;
  }

  .spaceAlingRight {
    padding-right: 15px !important;
  }

  .customIonSelect {
    padding-bottom: 8% !important;
  }

  .inpClass {
    padding-bottom: 4% !important;
  }

  .ion-item-height {
    height: 54.2% !important;
  }

  .ion-item-height1 {
    height: 18.2% !important;
  }

  .ion-margin-top-6 {
    margin-top: 6% !important;
  }

  .ion-padding-bottom-6 {
    padding-bottom: 4% !important;
  }

  .rbc-calendar {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }

  .calendar-heading,
  .new-event-desktop {
    font-weight: 500 !important;
    font-size: 28px !important;
  }

  .tfo-calendar-item,
  .tfo-calendar-item-big {
    height: 80%;
    line-height: 3;
    width: 250px;
  }

  [name="eventDesc"] {
    /* max-width: 78% !important; */
    margin-right: 25% !important;
    padding: 10px !important;
    height: unset !important;
  }

  .rbc-time-view {
    margin: 0 0 !important;
  }

  .ml-13 {
    /* margin-left: 13.2% !important; */
    margin-left: 180px !important;
  }

  .mr-13 {
    /* margin-right: 13.4% !important; */
    margin-right: 180px !important;
  }

  .fs-53 {
    font-size: 53px !important;
  }

  .mt-4 {
    margin-top: 4.7% !important;
  }

  .mt-1 {
    margin-top: 1.5% !important;
  }

  .sildervalleft {
    left: 3% !important;
  }

  .mw-78 {
    max-width: 78% !important;
  }

  ion-row.mobile-margin-right,
  ion-row.desktop-margin {
    margin-left: unset !important;
    margin-top: 1.5% !important;
  }

  .sc-ion-input-md-h {
    margin-bottom: unset !important;
  }

  .tab-selected {
    border-top: unset !important;
    border-bottom: 3px solid #fff !important;
  }

  .tab-btn {
    border-top: unset !important;
    height: 7vh !important;
    margin-top: 2% !important;
    max-width: 18% !important;
    border-bottom-width: 2px !important;
    margin-right: 4%;
  }

  ion-tabs>ion-tab-bar>ion-tab-button>ion-icon {
    width: 24%;
    height: 3.5vh;
    position: absolute;
    left: 0;
    bottom: 24%;
  }

  ion-tabs>ion-tab-bar>ion-tab-button>ion-label {
    position: absolute;
    font-size: 12px !important;
    left: 30%;
    /* bottom: 34%; */
  }

  .pos-abs-header {
    position: absolute !important;
    left: 180px !important;
    width: 50% !important;
    /* height: 9.5% !important; */
    border-bottom: unset !important;
    justify-content: flex-start !important;
  }

  .mr-7 {
    margin-right: 6.7% !important;
  }

  ion-row.margin-set,
  ion-item.margin-set,
  .fi-grid {
    /* margin-right: 13.4% !important; */
    /* margin-left: 13.2% !important; */
    margin-right: 180px !important;
    margin-left: 180px !important;
  }

  .mobile-reorder-grid {
    /* margin-right: 5.4% !important; */
    /* margin-left: 13.2% !important; */
    margin-right: 180px !important;
    margin-left: 180px !important;
  }

  ion-row.margin-unset,
  ion-item.margin-unset {
    margin-right: unset !important;
    margin-left: unset !important;
  }

  .chartFade,
  .chartOverlay {
    height: inherit !important;
  }

  .event-info-desktop {
    font-weight: 500 !important;
    font-size: 22px !important;
  }

  .mt-6 {
    margin-top: 2% !important;
  }

  .mt6 {
    margin-top: 6% !important;
  }

  .mr-6 {
    margin-right: 6% !important;
  }

  .to-do {
    font-size: 28px !important;
  }

  .mobile-moneylabel {
    font-size: 43px !important;
  }

  .fs-22 {
    font-size: 22px !important;
  }

  .fs-17 {
    font-size: 17px !important;
  }

  .fs-14 {
    font-size: 14px !important;
  }

  .mobile-dollar {
    height: 16px !important;
    left: -16px !important;
    vertical-align: super !important;
  }

  .schCard .cardTitle,
  .schCard .cardTitleGeo {
    font-size: 43px !important;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    z-index: 1;
    background-size: 10px auto;
    transition: border-color 0.3s;
    border: 1px solid #1182ff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .firstLeft>ion-card-header,
  .firstLeftNr>ion-card-header {
    font-size: 53px !important;
  }

  .firstLeftNb>ion-card-header {
    font-size: 34px !important;
  }

  .firstLeftDesk>ion-card-header,
  .firstLeftDeskNr>ion-card-header {
    font-size: 14px !important;
  }

  .firstLeftDesk>ion-card-header {
    font-size: 14px !important;
  }

  .fs-43 {
    font-size: 43px !important;
  }

  .fs-18 {
    font-size: 18px !important;
  }

  .fs-28 {
    font-size: 28px !important;
  }

  .fs-12 {
    font-size: 12px !important;
  }

  .mobile-flex-row>ion-button {
    height: 3.7vh;
  }

  .mt-unset {
    margin-top: unset !important;
  }

  .mobile-flex-align {
    margin-left: 13% !important;
    margin-top: 13% !important;
  }

  .font14 {
    font-size: 14px !important;
  }

  .searchCss {
    position: absolute;
    left: 50%;
  }

  .mh-18 {
    max-height: 4vh !important;
  }

  .fs-13 {
    font-size: 13px !important;
  }

  .fs-16,
  .TargetIonItem>ion-label>ion-row>ion-text {
    font-size: 16px !important;
  }

  .TargetIonItem>ion-label>ion-row>ion-icon,
  .fs-6 {
    font-size: 6px !important;
  }

  .insightsnewmeeting {
    right: 13% !important;
  }

  .padding-unset {
    padding: unset !important;
  }

  .marginbottomp24,
  .ion-margin-bottom {
    margin-bottom: unset !important;
  }

  /* .native-input.sc-ion-input-md {
padding-bottom: 7% !important;
} */
  .customformbtn,
  .mobile-slider-row {
    margin-left: unset !important;
  }

  .mt-unset {
    margin-top: unset !important;
  }

  .SegActive,
  .roww::after {
    border-bottom-width: 2px !important;
  }

  .todayToDoNormal,
  .todayToDo {
    margin-left: unset !important;
  }

  .todoMonthItem {
    margin-left: 12.1% !important;
  }

  .ml-12 {
    margin-left: 12.5% !important;
  }

  .timelineVerticalLineRed,
  .timelineVerticalLineNormal {
    margin-left: 18% !important;
  }

  /* ion-header {
    height: 9.5% !important;
  } */

  ion-toolbar {
    height: 100% !important;
  }

  .btncol-mobile>ion-button {
    text-transform: unset !important;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .popoverGridClass {
    height: unset !important;
  }

  ion-radio>ion-label {
    font-size: 14px !important;
  }

  .fw-unset {
    font-weight: unset !important;
  }

  .fs-22 {
    font-size: 22px !important;
  }

  #contact-info-content>ion-grid>.mobile-slider-row {
    margin-left: unset !important;
    margin-right: unset !important;
  }

  #contact-info-content>ion-grid.mt-1 {
    margin-left: 14.2% !important;
  }

  #contact-info-content>ion-grid>ion-row.mobile-padding0 {
    padding: unset !important;
  }

  .margin-unset {
    margin: unset !important;
  }

  .ml-14 {
    margin-left: 14.2% !important;
  }

  /* .form-row .input-items {
height: 34% !important;
} */
  .form-row .input-items ion-select {
    padding-bottom: 20px !important;
  }

  .toptargetsmallrank_a {
    left: 2.2% !important;
    bottom: 24% !important;
  }

  .toptargetsmallrank_b {
    left: 2.2% !important;
    bottom: 24% !important;
  }

  .toptargetsmallrank_c {
    left: 7.2% !important;
    bottom: 26% !important;
  }

  .toptargetsmallrank_d {
    left: 7.2% !important;
    bottom: 26% !important;
  }

  .toptargetstardashboard {
    bottom: 0% !important;
  }

  ion-row.roww {
    padding-left: 0.5% !important;
  }

  .h-70 {
    height: 70% !important;
  }

  [name="priphnum"],
  [name="whtphnum"] {
    margin-left: unset !important;
  }

  [name="phCode"],
  [name="phCodeWht"] {
    padding-bottom: 15% !important;
  }

  .grid-desktop {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .todo-filchips {
    margin-left: 172px !important;
    margin-right: 172px !important;
  }

  .viewevent-content>ion-row,
  .newevent-content>ion-row {
    margin-left: 180px !important;
    margin-right: 180px !important;
  }

  .desk-inv-req-att ion-item {
    margin-right: 25% !important;
    /* width: unset !important; */
  }

  .mobile-geo-margin {
    margin-left: 180px !important;
    margin-right: 180px !important;
  }

  .mobile-geo-margin>ion-col {
    margin: 0 !important;
    padding: 0 !important;
    text-align: left !important;
  }

  /* .my-custom-interface-width .select-interface-option {
--background: #222222;
--padding-start: 0px;
--color-checked: #fff;
margin-left:10px;
}
.my-custom-interface-width .item-radio-checked {
--background: #40412F;
}
.my-custom-interface-width .select-interface-option ion-label {
margin-left: 8px !important;
}
.my-custom-interface-width .select-interface-option ion-radio {
--color-checked: #fff;
} */
  .my-custom-interface-width .popover-content.sc-ion-popover-ios {
    width: 394px !important;
    margin-left: 16px !important;
  }

  .mobile-tododot,
  .desk-todo-title,
  .mobile-timeline-title {
    cursor: pointer;
  }
}

.my-custom-alert .alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 0.4em;
  display: block !important;
}

.my-custom-alert .alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.my-custom-alert .alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: #555;
  outline: 1px solid #555;
}

.text-hide {
  visibility: hidden;
}

.video-js .vjs-big-play-button {
  background-color: #fff;
  color: #2b333f;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 50px;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  border-color: #fff;
  background-color: #2b333f;
  color: #fff;
  transition: all 0.6s;
}

.custom-toaster {
  --background: rgba(171, 228, 184, 0.1);
  --color: white;
  --button-color: white;
  --ion-color-step-100: white;
  --max-width: 311px;
}