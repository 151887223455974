.popover-class {
    /* width: 244px !important; */
    padding: 0px !important;
    --backdrop-opacity: 0.7
}
.popover-class-A {
   /* width: 244px !important; */
   padding: 0px !important;
   --backdrop-opacity: 0.7;
   
}

 /* .TargetIonItem{
   --background: none !important;
   padding: 10px !important;
   margin-right: 10px !important;
   margin-left: 10px !important;
   background-color: #222222 !important;
   border-radius: 8px !important;
} */
/* .custom-popover .popover-content {
   width: 80%;
   top: 70px;
   left: 30px;
   bottom: 70px
 } */
/* .rmvpopover{
   width: 80%;
   top: 70px;
   left: 30px;
   bottom: 70px
} */

  .TargetIonItem.item-native{
   --border-radius:8px
 } 
.AddTargetIonItem{
   border-style:dashed;
}
.AddTargetIonItem 
{
    cursor: pointer;
}
.TargetAvatar{
   width: 48px !important;
   height: 48px !important;
}
.rmvpopover{
   background: #222222;
   border-radius: 8px;
   width: 100%;
   min-width: 100%;
} 
.ConfirmationButton{
   /* width: 26px;
   height: 17px; */
   color: #B99855; 
   font-family: Gotham;
   text-transform: none;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 120%;
   text-align: center;
   background: transparent !important;
} 
.nullvalue{
position: absolute;
height: 1px;
/* left: 0px;
right: 0px;
top: 0px; */ 
}
 
.conrmvtarget{
font-family: Gotham;
font-style: normal;
font-weight: normal;
font-size: 14px;
text-align: center;
color:#828282;
justify-content: center;
margin-top :16px;
margin-bottom :16px;
}
.rmvtarget{
font-family: Gotham;
font-size: 28px;
font-style: normal;
font-weight: 300;
color:#828282; 
justify-content: center;
margin-top :16px;
margin-bottom :16px;
}
.conrmvfooter
{
   text-align: center;
}
.my-custom-class
{
    --width: 400px;
    --height: 200px;
}
.popoverGridClass_A
{
    height: 200px;
}