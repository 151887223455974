.filtermenu-toolbar {
    --background: #111111 !important;
    height: unset !important;
    --border-width: 0 0 0 !important;
}
#filter-menu-notes {
    --background: #1a1a1a !important;
    --padding-start: 24px !important;
    --padding-end: 24px !important;
    --padding-top: 24px !important;
    --padding-bottom: 24px !important;
}
.my-custom-interface .select-interface-option {
    --background: #222222;
    --padding-start: 0px;
    --color-checked: #fff;
}
.my-custom-interface .item-radio-checked {
    --background: #40412F;
}
.my-custom-interface .select-interface-option ion-label {
    margin-left: 8px !important;
}
.my-custom-interface .select-interface-option ion-radio {
    --color-checked: #fff;
}
.popover-content.sc-ion-popover-ios {
    border-radius: 2px !important;
    width: 250px;
    --ion-safe-area-left: 10px;
}