.asocItem{
    --background: none !important;
    padding: 10px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
    background-color: #222222 !important;
    border-radius: 8px !important;
}

.asocCircleIcon{
    width: 8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    color: #777777 !important;
}

.asocArabicName{
    color: #777777 !important; 
}
.asocMutedText{
    color: #777777 !important; 
}
.asocAvatar{
    width: 48px !important;
    height: 48px !important;
}

.text:hover{
    border-bottom:2px solid white;
    padding-top:2px;
    
}