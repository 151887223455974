.totProspMeet
{
    border-left: 5px solid #B99855;
}
.conrmvfooter1 {
    text-align: center;
    /* margin-top: 50px; */
}

.ProspStat
{
    border-left: 5px solid #6D8A96;
}
.my-custom-interface .select-interface-option {
    --background: #222222;
    --padding-start: 0px;
    --color-checked: #fff;
}
.my-custom-interface .item-radio-checked {
    --background: #40412F;
}
.my-custom-interface .select-interface-option ion-label {
    margin-left: 8px !important;
}
.my-custom-interface .select-interface-option ion-radio {
    --color-checked: #fff;
}
.my-custom-interface ion-select::part(icon) {
    display: none !important;
}
.popover-content.sc-ion-popover-ios {
    border-radius: 2px !important;
    width: 250px;
    --ion-safe-area-left: 10px;
}
.next5Icon
{
    border-left: 5px solid #A5D2DC;
    
}
.CardGeo {
    margin-top: 32px;
    margin-bottom: 0px;
    margin-left: -5px;
    margin-right: 16px;
    cursor: pointer;    
    background-color: #1a1a1a;    
    
}
.CardPersona
{
    margin-top: 32px;
    margin-bottom: 0px;
    margin-left: -50px;
    margin-right: 0px;
    background-color: #1a1a1a;
    width: 300px!important; 
}
.CardPersonas
{
    margin-top: 32px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #1a1a1a;
    width: 300px!important; 
}
.clientstatdolicon{
    width: 16px !important;
    height: 17px !important;
}
.schCard ion-icon{
    width: 48px;
    height: 48px;
}

.schCard .titleRow{
    width: 100%;
    margin: 8px auto;
}
.schCard .titleRowPersona{
    width: 100%;
    margin: 16px auto;
}
.schCard .cardTitle {
    margin-right: auto;
    margin: auto 0 auto 0;
    font-size: 43px;
}
.schCard .cardSubTitleGeo {
    margin: auto auto 0 8px;
    font-size: 14px;
}
.schCard .cardTitleGeo {
    margin-left: auto;
}
.schCard .GeoIcon {
    margin: 30px auto 4px auto;
    width: 48px;
    height: 48px;
}

.cardCountryGeo {
    margin: 8px auto 24px auto;
    font-size: 14px;
}

.schCard .cardTitle {
    margin-right: auto;
    font-size: 43px;
}

.schCard .cardInGrid {
    margin-left: 16px;
}
.schCard ion-label {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.customIonSelect::part(icon)
{
    display: none!important;    
}
.customIonSelect::part(text){
    position: relative;
    top: 5px !important;
}
.select-icon
{
    visibility: hidden!important;
}
ion-select::part(icon)
{
    display:none!important;
    width:0;
}
.select-icon > .select-icon-inner
{
    display:none!important;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .schCard .cardInGrid {
        margin-left: 4px;
    }
    .clientstatdolicon{
        left: 20px !important;
        top: 6px !important;
        height:  10px !important;
    }
    
    .schCard ion-icon{
        width: 32px !important;
        /*height: 32px !important;*/
    }
    
    .schCard .cardTitle,
    .schCard .cardTitleGeo {
        font-size: 24px !important;
    }

}
@media (min-width: 1025px) and (-webkit-max-device-pixel-ratio: 1.75)  {
    .CardGeo {
        margin-right: 0px !important;
        
    }
    .conrmvfooter1 {
        text-align: center;
        margin-top: 34px;
    }
}