.rbc-event.event-default {
    background-color: #614940;
    /* box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(153, 153, 153, 0.4); */
}
.rbc-event{
    padding: 0px 2px;
}
.rbc-label {
    font-size: 12px;
    color: #828282;
    font-weight: 400;
}
.event-lightblue{
    background-color: lightblue;
}

.event-lightgreen{
    background-color: green;
}

.event-red{
    background-color: red;
}

.rbc-day-slot .rbc-time-slot {
    border-top: none!important;
}

.rbc-today {
    background-color:#111111!important;
}

.rbc-timeslot-group {
    min-height: 65px;
    border-bottom: 1px solid #222222 !important;
}

.rbc-time-view {
    border: none!important;
    margin: 0 -24px;
}

.rbc-time-content > * + * > * {
    border-left: none!important;
}
.rbc-time-column{
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
.rbc-time-header-content {
    border-left: none!important;
}
.rbc-time-header-content .rbc-time-header-cell .rbc-header {
    border-top: 1px solid #1d1d1d;
    border-bottom: 1px solid #1d1d1d;
}

.rbc-time-header.rbc-overflowing {
     border-right: none; 
}

.rbc-time-content > .rbc-day-slot {
     border-right: 1px solid #222222 !important;  
}
.rbc-time-content{
    border-top: none !important;
}

.rbc-date-cell{
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    color: #828282;
    margin-left: 12px;
    margin-top: 12px;
}
.rbc-allday-cell{
    display: none;
}

.rbc-date-cell.rbc-now {
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
}
.rbc-month-header {
    display: none;
}
.rbc-month-view {
    position: relative;
    border: none;
    display: flex;
    flex-direction: column;
    /* flex: 1 0; */
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    height: 100%;
    /* margin-top: 22%; */
}
.rbc-month-row + .rbc-month-row {
    border-top: none;
}   
.rbc-off-range-bg{
    background: none;
}
.rbc-header + .rbc-header{
    border-left: none !important;
}
.rbc-day-bg {
    border: 1px solid #222222 !important;
} 
.rbc-off-range {
    color: #4D4D4D !important;
}
.rbc-header{
    font-weight: 400;
    font-size: 18px;
    color: #828282;
    border: none;
}
.rbc-today{
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    border: none; 
}
.rbc-toolbar{
    margin-top: 8px;
    margin-right: 0px;
}

.rbc-toolbar-bottom{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    align-items: flex-start;
    margin-bottom: 20px;
    font-size: 16px;
    margin-left: 44px;
}
.cal-tab-active{
    border-bottom: 3px solid #B99855;
    --ion-color-medium: #B99855;
    font-weight: 700 !important;
}
.tfo-cal-event-title{
    font-size: 14px;
    padding: 5px;
    font-weight: 700;
    color: #FFFFFF;
}
.tfo-cal-event-dt-time{
    font-size: 12px;
    padding: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #C7C7C7;
    margin-top: 5px;
}
.tfo-cal-event-name{
    font-size: 14px;
    padding: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #C7C7C7;
}

.tfo-calendar-picker-input{
    background-color:#222222;
    cursor:pointer;
    width:100%;
}
 .tfo-cal-today-btn {
    --border-radius: 0px;
    --border-width: 3px solid;
    --border-color : "gold";
} 
.tfo-calendar-item {
    --background: #222222;
    margin-right: 2px;
    margin-bottom: 2px;
    border-radius: 0px;
    overflow: hidden;
    width: 250px;
}
.tfo-calendar-item-biggest{
    --background: #222222;
    margin-right: 2px;
    margin-bottom: 2px;
    border-radius: 0px;
    overflow: hidden;
    width: 395px; 
}
.tfo-calendar-item-big{
    --background: #222222;
    margin-right: 2px;
    margin-bottom: 2px;
    border-radius: 0px;
    overflow: hidden;
    width: 305px; 
}
.input-extended{
    width: 290px !important;
}
.calendar-heading{
    font-weight: 300px;
    font-size: 28px;
    line-height: 120%;
    color: #828282;
    margin-left: 0px;
}

.rbc-calendar{
    margin-left: 32px;
    margin-right: 32px;
}

.views-seg {
    margin-right: auto;
}
.new-event-btn-calendar {
    --background-activated: none;
    --background-hover: none;
    --background-focused: none;
    --background: none;
    color: #B99855;
    font-weight: 400;
    font-size: 14;
}
.rbc-toolbar-btm {
    margin-bottom: 24px;
}
.cal-content {
    --background: #111111 !important;
}
