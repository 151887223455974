.firstLeft_cal,
.firstLeftNb_cal,
.firstLeftNr_cal {
    position: relative;
    height: 100px;
    box-shadow: none;
    --background: none;
    border-radius: 0px;
    margin: 0px !important;
    padding-top: 2px;
    padding-bottom: 30px;
    /* padding-left: 40px; */
    /* padding-right: 40px; */
}
.firstLeft_cal::after,
.firstLeftNr_cal::after {
    content: " ";
    width: 95%;
    border-bottom: 1px solid #4d4d4d;
    position: absolute;
    bottom: 0;
    opacity: 0.5;
}
.firstLeft_cal::before,
.firstLeftNb_cal::before {
    content: " ";
    height: 80%;
    border-right: 1px solid #4d4d4d;
    position: absolute;
    right: 0;
    top: 10%;
    opacity: 0.5;
}

@media (min-width: 1025px) and (-webkit-max-device-pixel-ratio: 1.75)  {
    .firstLeft_cal_desk>ion-card-header,
    .firstLeft_cal_desk>ion-card-subtitle {
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .view-event-row-desktop {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

}