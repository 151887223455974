input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: rgb(26, 26, 26);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #A5D2DC;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #A5D2DC;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 0.15em solid #A5D2DC;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #A5D2DC;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input:focus-visible {
  outline-offset: 0px;
  background-color: none;
  outline: none;
}

input:focus {
  outline-offset: 0px;
  background-color: none;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.radioIcon {
  --color-checked: #A5D2DC;
  marginInlineEnd: 8;
}