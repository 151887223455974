.heroImage {
    width: 100%;
    object-fit: cover;
    height: 280px;
}

.cardSubTitles {
    font-weight: 600;
    font-size: 12px;
    margin-top: 8px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cardTitles {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #F9F9F9;
}

.InvcardTitle {
    font-weight: 700;
    font-size: 14px !important;
    line-height: 120%;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}

.InvcardSubTitle {
    font-weight: 400;
    font-size: 12px !important;
    line-height: 120%;
    color: #828282;
    margin-top: 8px;
}