.dealHeader {
  height: fit-content !important;
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}

.pagination li {
  padding-right: 5px;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  background: transparent;
  border: 2px solid rgb(185, 152, 85);
  color: rgb(185, 152, 85);
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: rgb(185, 152, 85);
  border: 2px solid rgb(185, 152, 85);
}

.pagination__link--disabled a {
  border: 2px solid #828282;
  color: #828282;
  font-weight: 400;
}

.tab {
  padding: 0px;
  color: #828282;
  font-weight: 400;
  margin-right: 15px;
  padding-bottom: 12px;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  outline: none;
}

.activeTab {
  color: #b99855;
  border-color: #b99855;
}