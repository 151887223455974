.topTargCard {
    width: 100%;
}
.vert-slide-margins {
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.toptargetstardashboard
{
    position: absolute;
    left: 0px;
    bottom: 3px;
    height: 46px;
    width: 25px;
}
.next-prev-cont {
    position: absolute;
    width: 100vw;
    height: 50px;
    left: -180px;
    right: 0;
    /* background-color: red; */
    bottom: 87px;
}
.swiper-button-next,
.swiper-button-prev {
    --background: #2A251B !important;
    background-image: none !important;
}
.swiper-button-next {
    right: 4.7% !important;
}
.swiper-button-prev {
    left: 9.2% !important;
}
.swiper-button-prev-cust ion-icon,
.swiper-button-next-cust ion-icon {
    width: 36px;
    height: 36px;
}
.swiper-button-prev-cust {
    position: absolute;
    left: 96px !important;
}
.swiper-button-next-cust {
    position: absolute;
    right: 120px !important;
}
.temp-cont {
    position: relative;
}
@media only screen and (min-width: 64.063em) { 
    .slider-tt {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}