.form-row ion-col:first-child {
    padding-left: 0px !important;
}

.form-row ion-col:last-child {
    padding-right: 0px !important;
}

.form-row .input-items {
    --padding-start: 0px;
    --background: #222222;
    margin-top: 6px !important;
    margin-bottom: 24px !important;
}
.form-row .input-items .native-input,
.form-row .input-items .native-textarea,
.form-row .input-items ion-select {
    --padding-start: 8px
}
.form-row .input-items .tfo-input {
    padding-left: 8px;
    width: 150% !important;
}
.form-row ion-radio {
    margin-right: 8px !important;
}
.newtodo-radio-list {
    background: none;
}
.newtodo-radio-item {
    --min-height: 32px;
}
.root-form-row {
    margin-top: 32px;
}
.my-custom-interface .select-interface-option {
    --background: #222222;
    --padding-start: 0px;
    --color-checked: #fff;
}
.my-custom-interface .item-radio-checked {
    --background: #40412F;
}
.my-custom-interface .select-interface-option ion-label {
    margin-left: 8px !important;
}
.my-custom-interface .select-interface-option ion-radio {
    --color-checked: #fff;
}
.my-custom-interface ion-select::part(icon) {
    display: none !important;
}
.endchevron {
    position: absolute !important;
    right: 5% !important;
}