.SharePopup-custom-class,
.new-popover-class {
  --backdrop-opacity: 0.7
}

.SharePopup-custom-class .popover-content {
  width: 600px;
  padding: 32px 28px 32px 36px;
  border-radius: 0px;
}
.SharePopup-custom-class .popover-viewport{
  padding-top: 10px;
}
.popover-class {
  /* width: 244px !important; */
  padding: 0px !important;
}
.popover-viewport .ShowPopupItem:first-child {
  margin-top: 32px !important;
}
  .sharebutton{
    font-size: large;
    font-weight: 700;
  }
.ShowPopupItem{
  --background: none !important;
    padding: 8px !important;
    background-color: #222222  !important;
    border-radius: 6px !important;
    font-size: large;
    margin-bottom: 2px;
}
.Newpopupitem .popover-content {
  width:600px
}
.new-popover-class .popover-content {
  width:240px;
}
.Newpopupitem{
  border-bottom: 1px groove;
    border-color: #8c867d;
    margin-left: 2%;
    display:block ;
    width: 250px;
}
.ShareText{
  /* padding-left: 10px; */
  font-size: 18px;
  font-weight: 700;
}

/* .item-md.item-block .item-inner {
  /* padding-right: 8px; */
  /* border-bottom: 1px solid #dedede;
    } */ 

    .item-inner{
      border-style: unset !important;
      }