.timelineVerticalLineRed {
  border-left: 1px solid;
  margin-left: 60px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-color: #C73D3D;
}
.timelineVerticalLineNormal {
  border-left: 1px solid;
  margin-left: 60px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-color: #4d4d4d;
}
.todoHorizontalLine{
  height: 90%;
  border-bottom: 1px groove;
  display: block;
  --background: none !important;
  background: none !important;
  margin-top: 2px;
  margin-bottom: 2px;;
}
.todoMonthItem{
  margin-left: 18px;
}

.todayToDo{
  margin-left: 29px !important;
  color: #C73D3D;
  font-size: 14px;
}
.todayToDoNormal{
  margin-left: 29px !important;
  color: #828282;
  font-size: 14px;
}
.todoIcon {
  left: 32px;
}
.todoItem{
  --background: none !important;
}
.todoCard
{
  border-left: 5px solid #a5d2dc;
  margin: 5px 5px 16px 5px;
  background-color: #222222 !important;
  /* width: 92%; */
}
.todoRedEllipse{
  margin-top: 55px;
  margin-left: 50px;
  color: #C73D3D;
  width: 15px;
  height: 15px;
}
.todoanchor
{
  color:#B99855;
  text-decoration:none;
}

ion-col .todoCard:last-child {
  margin-bottom: 72px !important;
}