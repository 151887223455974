  .boldText{
    font-weight: 700;
    color:white
}
/* .CardIonItem{
    --background: none !important;
    padding: 5px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
    background-color: #222222 !important;
    border-radius: 8px !important;
} */
.my-custom-classA
{
  --width: 500px;
  --height: 200px;
}

@media (min-width: 1025px) and (-webkit-max-device-pixel-ratio: 1.75) {
  .period-ellipse {
    font-size: 6px !important;
  }
}

@media only screen and (max-width: 575px) {
  .period-ellipse {
    font-size: 6px !important;
  }
}