.TargetBadge{
    background-color: #B99855 !important;
    color: black;
        border-radius:100%;
         height: 30px;
    width:30px; 
    height: 32px;
    width: 32px;
    font-weight: bolder;
    font-size: 16px;
    padding-top: 7px;
    padding-left: 5px;
 }

  .TargetIonItem.item-native{
   --border-radius:8px
 } 
.AddTargetIonItem{
   border-style:dashed;
}
.AddTargetBadge{
   background-color:#1A1A1A;
   color:gray;
   border-radius: 100%;
   font-weight:bolder;
   font-size:16px;
   height:32px;
   width:32px;
   border-style: solid;
   padding-left: 2px;
   padding-top: 5px;
   
}
.AddTargetIonItem 
{
    cursor: pointer;
}
.TargetAvatar{
   width: 48px !important;
   height: 48px !important;
}
.rmvpopover_a{
   background: #222222;
   border-radius: 8px;
   width: 450px;
    min-width: 450px;
    height: 200px;
    min-height: 200px;
} 
.dateSelectPop{
   background: #222222;
   border-radius: 8px;
   width: 450px;
    min-width: 450px;
    height: 250px;
    min-height: 250px;
} 

.delete25popover
{   
   --width: 450px;
    --height: 200px;
    --min-height: 200px;
    --min-width: 450px;
}
@media only screen and (max-device-width: 576px){
   .fromToDate
   {
      --width: 450px! important;
      --height: 250px! important;
      --min-height: 284px! important;
      --min-width: 300px ! important  ;
   } 
   .marlft100{
   margin-left: -110px ! important;
   }
   .marlft60{
    margin-left: -60px ! important;
   }
   /* .marlft8{
      margin-left: 8px ! important;
   } */
   .rmvtarget{
      font-family: Gotham;
      font-size: 28px;
      font-style: normal;
      font-weight: 300;
      color:#828282; 
      justify-content: center;
      margin-top :35px;
      margin-bottom :16px;
      }
      
      .conrmvfooter
      {
         text-align: center;
         margin-top:25px ! important;
      }
}
.fromToDate
{
   --width: 450px;
   --height: 250px;
   --min-height: 250px;
   --min-width: 450px;
}
.ConfirmationButton{
   /* width: 26px;
   height: 17px; */
   color: #B99855; 
   font-family: Gotham;
   text-transform: none;
   font-style: normal;
   font-weight: normal;
   font-size: 14px;
   line-height: 120%;
   text-align: center;
   background: transparent !important;
} 
.nullvalue{
position: absolute;
height: 1px;
/* left: 0px;
right: 0px;
top: 0px; */ 
}
 
.conrmvtarget{
font-family: Gotham;
font-style: normal;
font-weight: normal;
font-size: 14px;
text-align: center;
color:#828282;
justify-content: center;
margin-top :16px;
margin-bottom :16px;
}
.rmvtarget{
font-family: Gotham;
font-size: 28px;
font-style: normal;
font-weight: 300;
color:#828282; 
justify-content: center;
margin-top :16px;
margin-bottom :16px;
}
.conrmvfooter
{
   text-align: center;
   margin-top:50px;
}
.nulltargetrow2
{
   margin-top:25px;
   font-style: normal;
font-weight: 300;
font-size: 43px;
line-height: 120%;
justify-content: center;
}
.nulltargetrow3
{
   margin-top:25px;
   font-size: 18px;
   line-height: 120%;
   justify-content: center;
}
.deletetargetpopoverclass
{
   --width: 400px;
   --height: 200px;
   --background: none;
   --backdrop-opacity: 0;
   --box-shadow:0;
}
