.ClientDataAUMCard{
    height: 340px;
    Width: 470px;
    background: #1A1A1A;
    /* box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.75); */
    
    
}
.ClientDataAUMList{
    background: #1A1A1A;
    
}

.RowHeading{
    font-size:18px;
    margin-bottom:25px;
    color:white;
    padding-left: 17px;
    /* font-family: Gotham Book; */

}
.Row1{
    padding-top:5px;
    /* font-family: Almarai; */
}
.Row2{
    color:#828282;
    font-size:14px;
    margin-bottom: 8px;
    padding-top: 5px;
    /* font-family: Gotham Book; */
}
.ClientDatLastContactCard{
    height: 340px;
    Width: 470px;
    background: #1A1A1A;
    /* left: 550px;
    bottom: 375px; */

}
.ClientDataLastContactList{
    background: #1A1A1A;

}

@media (min-width: 1025px) and (-webkit-max-device-pixel-ratio: 1.75)  {

    .ClientDatLastContactCard{
        width: 97% !important;
      }
      .ClientDataAUMCard {
         width: 98% !important; 
      }
}

@media only screen and (device-width: 768px) {

    .ClientDatLastContactCard{
        width: 323px !important;
      }
      .ClientDataAUMCard {
         width: 323px !important; 
      }
}