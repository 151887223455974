.colValue{
    width:150px !important;
    height:70px !important;
}

.rangeval{
    --pin-color:"gold";
    --pin-background:"gold";
    --knob-size: 30px;
    --knob-box-shadow: 5px;
    --knob-background:"gold";
    width:152%;

}
.sliderposition{
    position: "relative";
    right: 28%;
}