ion-searchbar {
    --background: #222222
}
.search-content {
    --background: #111111 !important;
}
@media (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2) 
{
    .searchbar-pros-client
    {
        margin-top:-15px;
    }
}