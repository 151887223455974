ion-toolbar {
    --background: #222222
}

ion-avatar {
    --border-radius: 150px
}
.item-avatar  {     
    width:50% !important;  
    height : 50% !important;  
    max-width: 25px !important;
    max-height: 25px !important; 
}

.srchplaceholder {
    color: #828282;
    font-style: italic;
}

.rootgrid {
    margin: 8px 16px;
}

.trig-srch {
    cursor: pointer;
}