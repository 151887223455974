.timelineVerticalLine
{
    border-left: 1px groove;
    height: 40px;
    margin-left: 50px;
    margin-top: -10px;
    margin-bottom: -10px;
    color:#4D4D4D;
}
.grayscale
{
    color:#828282!important;
}
.white
{
    color:#ffffff !important;
}

@media (min-width: 1025px) and (-webkit-max-device-pixel-ratio: 1.75) {
    .timeline-period {
        font-size: 6px !important;
    }
}
@media only screen and (max-width: 575px) {
    .timelineitem
    {
        margin-left: 8px;
    }
    .ionitemTLMonthName
    {
        margin-left: 8px;
    }
    .TLline
    {
        margin-left: 25px!important;
    }
}