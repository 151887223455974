.inpClass
{
    background: #222222;
    border-radius: 2px;
    /* margin-top:10px; */
    /* color:#828282; */
    padding-left: 8px !important;
}
.marginbottomp15
{
    margin-bottom: 15px;
}
.marginbottomp24
{
    margin-bottom: 24px;
}
.margintopn15
{
    margin-top:-15px;
}
.margintopn20
{
    margin-top:-20px;
}
.marginleftp10
{
    margin-left:10px;
}

.customIonSelect
{
    width:100%;
}
.customIonSelect div.select-text
{
    text-align: left;
    
}
.customformbtn
{
    border:2px solid #B99855;
    --border-radius:0px;
    margin-top: 24px !important;
}
.my-custom-interface .select-interface-option {
    --background: #222222;
    --padding-start: 0px;
    --color-checked: #fff;
}
.my-custom-interface .item-radio-checked {
    --background: #40412F;
}
.my-custom-interface .select-interface-option ion-label {
    margin-left: 8px !important;
}
.my-custom-interface .select-interface-option ion-radio {
    --color-checked: #fff;
}
.popover-content.sc-ion-popover-ios {
    border-radius: 2px !important;
    width: 250px;
    --ion-safe-area-left: 10px;
}
.my-custom-interface-nationality .popover-content.sc-ion-popover-ios {
    border-radius: 2px !important;
    width: 400px;
    --ion-safe-area-left: 10px;
}
.my-custom-interface-width .select-interface-option {
    --background: #222222;
    --padding-start: 0px;
    --color-checked: #fff;
    margin-left:10px;
}
.my-custom-interface-width .item-radio-checked {
    --background: #40412F;
}
.my-custom-interface-width .select-interface-option ion-label {
    margin-left: 8px !important;
}
.my-custom-interface-width .select-interface-option ion-radio {
    --color-checked: #fff;
}
.my-custom-interface-width .popover-content.sc-ion-popover-ios{
    width: 343px !important;
    margin-left: 21px !important;
}
.verticalline
{
    border: 1px solid;
    color: #222222;
}
ion-select,
ion-input {
    --placeholder-color: #828282 !important;
}
ion-select {
    font-size: 14px;
}
[name="priphnum"]
{
    border:none;
    width: 265px;
    height: 40px;
    margin-left: 16px;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}
[name="whtphnum"]
{
    border:none;
    width: 268px;
    height: 40px;
    margin-left: 16px;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

[name="whtphnum"]:focus{
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}
[name="priphnum"]:focus{
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}
[name="whtphnum"]::-webkit-inner-spin-button{ 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
[name="priphnum"]::-webkit-inner-spin-button{ 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
[aria-checked=true].sc-ion-alert-md .alert-checkbox-icon.sc-ion-alert-md{
    --ion-color-primary: #A5D2DC;
    --ion-color-primary-contrast: #222222;
}
.alert-button-inner.sc-ion-alert-md{
    color: #B99855;
}
.my-custom-interfacesec .select-interface-option {
    --background: #222222;
    --padding-start: 0px;
    --color-checked: #fff;
    margin-left:10px;
}
.my-custom-interfacesec .item-radio-checked {
    --background: #40412F;
}
.my-custom-interfacesec .select-interface-option ion-label {
    margin-left: 8px !important;
}
.my-custom-interfacesec .select-interface-option ion-radio {
    --color-checked: #fff;
}
.my-custom-interface-journey
{
    margin-left: 21px;
}

.my-custom-interfacesec .popover-content.sc-ion-popover-ios{
    width: 380px !important;
    margin-left: 21px !important;
}
/* @media (min-width: 1025px) and (-webkit-max-device-pixel-ratio: 1.75)  {
    .my-custom-interface.sc-ion-alert-md-h{
        --min-width: 414px;
    }
} */

/* @media (min-width: 1025px) and (-webkit-max-device-pixel-ratio: 1.75)   */

    .my-custom-interfacealert .alert-button.ion-activatable~.ion-activatable
    {   
        position: absolute !important;  
        right: 65% !important;
    }
