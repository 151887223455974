.boldText
{
    font-weight: 700;
}
.reducetopmargin
{
    margin-top: -10px;
}
.borderBottomClass
{
    border-bottom: 1px solid;
}
.themebkgd
{
    --background: #1a1a1a;
}
.neg40margintop
{
    margin-top: -30px;
}
.bottomBorder95
{
    border-bottom: 1px solid;
    width: 92%;
    border-color: #4d4d4d;
    opacity: 0.5;
}
.rightBorder95
{
    border-left: 1px solid;
    position: absolute;
    top: 13%;
    bottom: 22%;
    left: -4%;
    border-color: #4d4d4d;
    opacity: 0.5;
}
.preAddInfoBorder
{
    border-bottom: 1px solid;
    border-color: #222222;
    width:97%;
    margin-left: 2%;
    margin-bottom: 15px;
    margin-top: 10px;
}
/* .bioDiv
{
    margin-left:25px;
    margin-right:16px;
    margin-top: -15px;
    margin-bottom: 20px;
} */
.primarygold
{
    color: #B99855;
}
.font50
{
    font-size: 50px;
}
.font12
{
    font-size: 12px;
}
.font14
{
    font-size: 14px;
}
.font18
{
    font-size: 18px!important;
}
.font30
{
    font-size: 30px;
}
.font32
{
    font-size: 30px !important;
}
.scheduleIcon
{
    border-left: 5px solid #F7B198;
    --color:"yellow";
}
.bioDesc
{
    font-size: 12px;
    line-height: 200%;
    color: #AAAAAA;
}
.scheduletitleClass
{
    font-size: 18px;
}
.schedulebtnClass
{
    font-size: 14px;
}
.scheduleheaderClass
{
    --background: #1a1a1a;
}
/* ion-card {
    --color: yellow;
} */
.finlastrow 
{
    margin-bottom:5px;
}
.shariacomp
{
    margin: 0 7px -2px 0px;
}
.toptargeticon
{
    margin: 0 7px -2px 0px;
}
.polexposedicon
{
    margin: 0 7px -2px 0;
}
.grayscale4
{
    color: #828282
}
.grayscale5
{
    color:#AAAAAA!important;
}
.baseInfop1
{
    font-size: 53px;
    font-weight: 300;   
    line-height: 120%;
    margin-bottom: 0px;
    margin-top: 11px;
}
.baseInfop2
{
    font-size: 34px;
    font-weight: 300;   
    line-height: 120%;
    margin-bottom: 0px;
}
.baseInfop3
{
    font-size: 34px;
    font-weight: 300;   
    line-height: 120%;
    margin-bottom: 0px;
}
/* .baseInforow
{
    margin-bottom:20px;
} */
.basicInfo4btn
{
    line-height: 120%;
    text-align: right;
    height: 24px;
    color: #222222;
    font-size: 10px;
    --background: #A5D2DC;
}
.fi-grid ion-row ion-col,
.bi-grid ion-row ion-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
p[color="sophisred"]
{
    color:#C73D3D;
}
p[color="sophispale"]
{
    color:#AFEEEE;
}
p[color="sophisgreen"]
{
    color:#B5E361;
}
.nameClass
{
    font-weight: 300;
    font-size: 28px;
    line-height: 120%;
    color: #828282;
}
.baseaddInfo
{
    margin-top: 10px!important;
    line-height: 120%!important;
}
.lineheight120
{
    line-height: 120%;
}
.marginbottom5
{
    margin-bottom: 5px;
}
.upcomingScheduleSlide
{
    margin-left:-20px;
}
.grayscale6
{
    color:#C7C7C7;
}
.startarget
{
    margin-right: 5px;
    height: 20px;
    width: 20px;
    margin: -6px 3px -3px 0;
}
.toptargetedit
{
    cursor: pointer;
    color: #B99855;
    --box-shadow: none;
    --padding-bottom: 11px;
    border :none;
    --background: #141414;

}
.addastoptrgt
{
    cursor: pointer;
    color: #B99855;
    --box-shadow: none;
    border :none;
    --background: transparent;
    font-size: 14px;
    --padding-bottom: 10px;

}
.toptargetrank
{
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #1A1A1A;
    position: absolute;
    left: 8px;
    bottom: 4px
}
.toptargetstar
{
    position: absolute;
    left: 0px;
    height: 24px;
    width: 24px;
    bottom:0px;
}
.star-group {
    position: absolute;
}
