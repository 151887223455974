.SegActive {
    border-bottom: 4px solid #B99855 !important;
    color: #B99855 !important;
    font-weight: 500 !important;
}
.roww::after{
    content: " ";
    border-bottom: 4px solid #4d4d4d;
    width: 100%;
    position: absolute;
    bottom: 0px;
    z-index: -1;
}

.roww {
    display: flex;
}
.roww ion-text {
    margin-left: auto;
    margin-right: auto;
}
.roww ion-text:first-child {
    margin-left: 0px !important;
}
.roww ion-text:last-child {
    margin-right: 0px !important;
}