.call-buttons
{
    --background: none;
    margin-left: -8px;
    margin-right: -8px;
}
.toptargetsmallstar
{
    position: absolute;
    left: 0px;
    bottom: 3px;
    height: 46px;
    width: 25px;
}
.toptargetsmallstarlist
{
    position: absolute;
    left: 0px;
    bottom: 40px;
    height: 46px;
    width: 25px;
}
.toptargetsmallrank_a
{
    color: #1A1A1A;
    position: absolute;
    left: 9px;
    bottom: 18px;
    font-weight: 700;
    font-size: 12px;
}

.toptargetsmallrank_b
{
    color: #1A1A1A;
    position: absolute;
    left: 5px;
    bottom: 18px;
    font-weight: 700;
    font-size: 12px;
}
.listallprosprank
{
    color: #1A1A1A;
    position: absolute;
    left: 8.2%!important;
    bottom: 54%!important;
    font-weight: 700;
    font-size: 12px;
}
.toptargetsmallrank_c
{
    color: #1A1A1A;
    position: absolute;
    left: 9px;
    bottom: 18px;
    font-weight: 700;
    font-size: 12px;
}
.toptargetsmallrank_d
{
    color: #1A1A1A;
    position: absolute;
    left: 5px;
    bottom: 18px;
    font-weight: 700;
    font-size: 12px;
}
.allclientsellipse
{
    font-size: 6px !important;
    margin-right: 4px;
    margin-top: 5px;
    color: #828282;
    margin-left:4px
}
@media (min-width: 1025px) and (-webkit-max-device-pixel-ratio: 1.75) {
    .allclientsellipse
    {
        font-size: 6px !important;
        margin-right: 4px;
        margin-top: 7px;
        color: #828282;
        margin-left:4px
    }
}

.custTotMeetSelect    {
    --placeholder-color: rgb(185, 152, 85)!important;
    --placeholder-opacity: 1;
}
#list-watsapp-nwln
{
    margin-left:-17px;
    margin-top: -8px;
}