.dashgrid {
    margin-left: "32px !important";
    margin-right: "32px !important";
    background: "none";
}

.mainTitle {
    font-weight: "300";
    font-size: "24px !important";
    line-height: "120%";
    color: "#C7C7C7"
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .margin-set {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
}