
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .labelssvg {
        left: 64px !important;
    }    
    .axessvg {
        left: 64px !important;

    }
}
.bar 
{
    padding-left: 60px;
    padding: 0 auto;
}
.sBar:hover {
    fill: brown;
}