/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /* custom theme */

  --ion-background-color: #131313;
  --ion-background-color-rgb: 26,26,26;

  --ion-text-color: #fdfdfd;
  --ion-text-color-rgb: 253,253,253;

  --ion-color-step-50: #252525;
  --ion-color-step-100: #313131;
  --ion-color-step-150: #3c3c3c;
  --ion-color-step-200: #474747;
  --ion-color-step-250: #535353;
  --ion-color-step-300: #5e5e5e;
  --ion-color-step-350: #696969;
  --ion-color-step-400: #757575;
  --ion-color-step-450: #808080;
  --ion-color-step-500: #8c8c8c;
  --ion-color-step-550: #979797;
  --ion-color-step-600: #a2a2a2;
  --ion-color-step-650: #aeaeae;
  --ion-color-step-700: #b9b9b9;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
 
  /* gold color */
  --ion-color-gold: #b99855;
  --ion-color-gold-rgb: 185, 152, 85;
  --ion-color-gold-contrast: #ffffff;
  --ion-color-gold-contrast-rgb: 255,255,255;
  --ion-color-gold-shade: #ffffff;
  --ion-color-gold-tint: #ffffff;
  
  /* Primary / Active Blue - To Do*/
  --ion-color-actblue: #A5D2DC;
  --ion-color-actblue-rgb: 165, 210, 220;
  --ion-color-actblue-contrast: #ffffff;
  --ion-color-actblue-contrast-rgb: 255,255,255;
  --ion-color-actblue-shade: #ffffff;
  --ion-color-actblue-tint: #ffffff;

  /* Primary / Active Blue - To Do -checkbox version*/
  --ion-color-actblue-v1: #A5D2DC;
  --ion-color-actblue-v1-rgb: 165, 210, 220;
  --ion-color-actblue-v1-contrast: black;
  --ion-color-actblue-v1-contrast-rgb: 255,255,255;
  --ion-color-actblue-v1-shade: #ffffff;
  --ion-color-actblue-v1-tint: #ffffff;

  /* Primary / Gold - To Do -checkbox version*/
  --ion-color-gold-v1: #b99855;
  --ion-color-gold-v1-rgb: 185, 152, 85;
  --ion-color-gold-v1-contrast: black;
  --ion-color-gold-v1-contrast-rgb: 255,255,255;
  --ion-color-gold-v1-shade: #ffffff;
  --ion-color-gold-v1-tint: #ffffff;

     /* Alternate / 1 Email */
  --ion-color-altem: #A5BFC5;
  --ion-color-altem-rgb: 165, 191, 197;
  --ion-color-altem-contrast: #ffffff;
  --ion-color-altem-contrast-rgb: 255,255,255;
  --ion-color-altem-shade: #ffffff;
  --ion-color-altem-tint: #ffffff;
  /* Alternate / 2  Client App*/
  --ion-color-altclient: #6D8A96;
  --ion-color-altclient-rgb: 109, 138, 150;
  --ion-color-altclient-contrast: #ffffff;
  --ion-color-altclient-contrast-rgb: 255,255,255;
  --ion-color-altclient-shade: #ffffff;
  --ion-color-altclient-tint: #ffffff;
   /* Alternate / 10 Meeting*/
  --ion-color-atlmeet: #F7B198;
  --ion-color-atlmeet-rgb: 247, 177, 152;
  --ion-color-atlmeet-contrast: #ffffff;
  --ion-color-atlmeet-contrast-rgb: 255,255,255;
  --ion-color-atlmeet-shade: #ffffff;
  --ion-color-atlmeet-tint: #ffffff;
  /* Accent / Success Web Form*/
  --ion-color-successweb: #B5E361;
  --ion-color-successweb-rgb: 181, 227, 97;
  --ion-color-successweb-contrast: #ffffff;
  --ion-color-successweb-contrast-rgb: 255,255,255;
  --ion-color-successweb-shade: #ffffff;
  --ion-color-successweb-tint: #ffffff;
  /* Alternate / 3 Success Phone Call*/
  --ion-color-successph: #5DA683;
  --ion-color-successph-rgb: 93, 166, 131;
  --ion-color-successph-contrast: #ffffff;
  --ion-color-successph-contrast-rgb: 255,255,255;
  --ion-color-successph-shade: #ffffff;
  --ion-color-successph-tint: #ffffff;
 /* Accent / Alert Unsuccessful phone call*/
  --ion-color-alertph: #C73D3D;
  --ion-color-alertph-rgb: 199, 61, 61;
  --ion-color-alertph-contrast: #ffffff;
  --ion-color-alertph-contrast-rgb: 255,255,255;
  --ion-color-alertph-shade: #ffffff;
  --ion-color-alertph-tint: #ffffff;
  /* #25D366 = WhatsApp */
  --ion-color-whatsapp: #25D366;
  --ion-color-whatsapp-rgb: 37, 211, 102;
  --ion-color-whatsapp-contrast: #ffffff;
  --ion-color-whatsapp-contrast-rgb: 255,255,255;
  --ion-color-whatsapp-shade: #ffffff;
  --ion-color-whatsapp-tint: #ffffff;
  /* #828282 = Greyscale */
  --ion-color-greyscale: #828282;
  --ion-color-greyscale-rgb: 130, 130, 130;
  --ion-color-greyscale-contrast: #ffffff;
  --ion-color-greyscale-contrast-rgb: 255,255,255;
  --ion-color-greyscale-shade: #ffffff;
  --ion-color-greyscale-tint: #ffffff;
  /* #222222 = Greyscale */
  --ion-color-greyscale2: #222222;
  --ion-color-greyscale2-rgb: 34, 34, 34;
  --ion-color-greyscale2-contrast: #ffffff;
  --ion-color-greyscale2-contrast-rgb: 255,255,255;
  --ion-color-greyscale2-shade: #ffffff;
  --ion-color-greyscale2-tint: #ffffff;
  /* #222222 = GreyscaleBtn */
  --ion-color-greyscaleBtn: #222222;
  --ion-color-greyscaleBtn-rgb: 34, 34, 34;
  --ion-color-greyscaleBtn-contrast: #b99855;
  --ion-color-greyscaleBtn-contrast-rgb: 255,255,255;
  --ion-color-greyscaleBtn-shade: #b99855;
  --ion-color-greyscaleBtn-tint: #b99855;
  /* #4B3933 = Alt 8 */
  --ion-color-alt8: #4B3933;
  --ion-color-alt8-rgb: 75, 57, 51;
  --ion-color-alt8-contrast: #ffffff;
  --ion-color-alt8-contrast-rgb: 255,255,255;
  --ion-color-alt8-shade: #ffffff;
  --ion-color-alt8-tint: #ffffff;
  /* #c7c7c7 = grey c7 */
  --ion-color-greyc7: #c7c7c7;
  --ion-color-greyc7-rgb: 75, 57, 51;
  --ion-color-greyc7-contrast: #ffffff;
  --ion-color-greyc7-contrast-rgb: 255,255,255;
  --ion-color-greyc7-shade: #ffffff;
  --ion-color-greyc7-tint: #ffffff;
  
  /* font */
  --ion-font-family:Gotham,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji
}

/* @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Gotham/English/Gotham-Light.ttf');
}
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Gotham/English/Gotham-Bold.ttf');
}
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Gotham/English/Gotham-Book.ttf');
}
@font-face {
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Almarai/Almarai-Regular.ttf');
}




.ion-color-gold {
  --ion-color-base: var(--ion-color-gold);
  --ion-color-base-rgb: var(--ion-color-gold-rgb);
  --ion-color-contrast: var(--ion-color-gold-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
  --ion-color-shade: var(--ion-color-gold-shade);
  --ion-color-tint: var(--ion-color-gold-tint);
}
.ion-color-actblue {
  --ion-color-base: var(--ion-color-actblue);
  --ion-color-base-rgb: var(--ion-color-actblue-rgb);
  --ion-color-contrast: var(--ion-color-actblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-actblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-actblue-shade);
  --ion-color-tint: var(--ion-color-actblue-tint);
}
.ion-color-actblue-v1 {
  --ion-color-base: var(--ion-color-actblue);
  --ion-color-base-rgb: var(--ion-color-actblue-rgb);
  --ion-color-contrast: black;
  --ion-color-contrast-rgb: var(--ion-color-actblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-actblue-shade);
  --ion-color-tint: var(--ion-color-actblue-tint);
}
.ion-color-altem {
  --ion-color-base: var(--ion-color-altem);
  --ion-color-base-rgb: var(--ion-color-altem-rgb);
  --ion-color-contrast: var(--ion-color-altem-contrast);
  --ion-color-contrast-rgb: var(--ion-color-altem-contrast-rgb);
  --ion-color-shade: var(--ion-color-altem-shade);
  --ion-color-tint: var(--ion-color-altem-tint);
}
.ion-color-altclient {
  --ion-color-base: var(--ion-color-altclient);
  --ion-color-base-rgb: var(--ion-color-altclient-rgb);
  --ion-color-contrast: var(--ion-color-altclient-contrast);
  --ion-color-contrast-rgb: var(--ion-color-altclient-contrast-rgb);
  --ion-color-shade: var(--ion-color-altclient-shade);
  --ion-color-tint: var(--ion-color-altclient-tint);
}
.ion-color-atlmeet {
  --ion-color-base: var(--ion-color-atlmeet);
  --ion-color-base-rgb: var(--ion-color-atlmeet-rgb);
  --ion-color-contrast: var(--ion-color-atlmeet-contrast);
  --ion-color-contrast-rgb: var(--ion-color-atlmeet-contrast-rgb);
  --ion-color-shade: var(--ion-color-atlmeet-shade);
  --ion-color-tint: var(--ion-color-atlmeet-tint);
}
.ion-color-successweb {
  --ion-color-base: var(--ion-color-successweb);
  --ion-color-base-rgb: var(--ion-color-successweb-rgb);
  --ion-color-contrast: var(--ion-color-successweb-contrast);
  --ion-color-contrast-rgb: var(--ion-color-successweb-contrast-rgb);
  --ion-color-shade: var(--ion-color-successweb-shade);
  --ion-color-tint: var(--ion-color-successweb-tint);
}
.ion-color-successph {
  --ion-color-base: var(--ion-color-successph);
  --ion-color-base-rgb: var(--ion-color-successph-rgb);
  --ion-color-contrast: var(--ion-color-successph-contrast);
  --ion-color-contrast-rgb: var(--ion-color-successph-contrast-rgb);
  --ion-color-shade: var(--ion-color-successph-shade);
  --ion-color-tint: var(--ion-color-successph-tint);
}
.ion-color-alertph {
  --ion-color-base: var(--ion-color-alertph);
  --ion-color-base-rgb: var(--ion-color-alertph-rgb);
  --ion-color-contrast: var(--ion-color-alertph-contrast);
  --ion-color-contrast-rgb: var(--ion-color-alertph-contrast-rgb);
  --ion-color-shade: var(--ion-color-alertph-shade);
  --ion-color-tint: var(--ion-color-alertph-tint);
}
.ion-color-whatsapp {
  --ion-color-base: var(--ion-color-whatsapp);
  --ion-color-base-rgb: var(--ion-color-whatsapp-rgb);
  --ion-color-contrast: var(--ion-color-whatsapp-contrast);
  --ion-color-contrast-rgb: var(--ion-color-whatsapp-contrast-rgb);
  --ion-color-shade: var(--ion-color-whatsapp-shade);
  --ion-color-tint: var(--ion-color-whatsapp-tint);
}
.ion-color-greyscale {
  --ion-color-base: var(--ion-color-greyscale);
  --ion-color-base-rgb: var(--ion-color-greyscale-rgb);
  --ion-color-contrast: var(--ion-color-greyscale-contrast);
  --ion-color-contrast-rgb: var(--ion-color-greyscale-contrast-rgb);
  --ion-color-shade: var(--ion-color-greyscale-shade);
  --ion-color-tint: var(--ion-color-greyscale-tint);
}
.ion-color-greyscale2 {
  --ion-color-base: var(--ion-color-greyscale2);
  --ion-color-base-rgb: var(--ion-color-greyscale2-rgb);
  --ion-color-contrast: var(--ion-color-greyscale2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-greyscale2-contrast-rgb);
  --ion-color-shade: var(--ion-color-greyscale2-shade);
  --ion-color-tint: var(--ion-color-greyscale2-tint);
}
.ion-color-greyscaleBtn {
  --ion-color-base: var(--ion-color-greyscaleBtn);
  --ion-color-base-rgb: var(--ion-color-greyscaleBtn-rgb);
  --ion-color-contrast: var(--ion-color-greyscaleBtn-contrast);
  --ion-color-contrast-rgb: var(--ion-color-greyscaleBtn-contrast-rgb);
  --ion-color-shade: var(--ion-color-greyscaleBtn-shade);
  --ion-color-tint: var(--ion-color-greyscaleBtn-tint);
}
.ion-color-alt8 {
  --ion-color-base: var(--ion-color-alt8);
  --ion-color-base-rgb: var(--ion-color-alt8-rgb);
  --ion-color-contrast: var(--ion-color-alt8-contrast);
  --ion-color-contrast-rgb: var(--ion-color-alt8-contrast-rgb);
  --ion-color-shade: var(--ion-color-alt8-shade);
  --ion-color-tint: var(--ion-color-alt8-tint);
}
.ion-color-greyc7 {
  --ion-color-base: var(--ion-color-greyc7);
  --ion-color-base-rgb: var(--ion-color-greyc7-rgb);
  --ion-color-contrast: var(--ion-color-greyc7-contrast);
  --ion-color-contrast-rgb: var(--ion-color-greyc7-contrast-rgb);
  --ion-color-shade: var(--ion-color-greyc7-shade);
  --ion-color-tint: var(--ion-color-greyc7-tint);
}

/* @media (prefers-color-scheme: dark) { */
  /*
   * Dark Colors
   * -------------------------------------------
   */

  /* body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  } */

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  /* .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  } */


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  /* .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  } */
/* } */
