.cnt-buttons {
    color: #222222 !important
}
.profhref
{
    text-decoration: none;
    color: #222222 !important;
}
.firstLeft,
.firstLeftNb,
.firstLeftNr {
    position: relative;
    height: 150px;
    box-shadow: none;
    --background: none;
    border-radius: 0px;
    margin: 0px !important;
    padding-top: 2px;
    padding-bottom: 30px;
    /* padding-left: 40px; */
    /* padding-right: 40px; */
}
.firstLeft::after,
.firstLeftNr::after {
    content: " ";
    width: 95%;
    border-bottom: 1px solid #4d4d4d;
    position: absolute;
    bottom: 0;
    opacity: 0.5;
}
.firstLeft::before,
.firstLeftNb::before {
    content: " ";
    height: 80%;
    border-right: 1px solid #4d4d4d;
    position: absolute;
    right: 0;
    top: 10%;
    opacity: 0.5;
}
.percGrey::before {
    content: " ";
    position: absolute;
    border-bottom: 4px solid #828282;
    z-index: -1;
    width: 75%;
    bottom: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {

    .exp_sub {
        width: 85%;
    }

}
.showmore{
    color: #AAAAAA !important;
    font-weight: 400px !important;
    margin-right: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 200%;
}
.my-custom-reinvest-select
{
    margin-left: 125px;
    margin-top:-4px;
    color:#B99855;
}
.insightsnewmeeting
{
    position: absolute;
    right: 78px;
    cursor: pointer;
}