ion-select::part(icon) {
    display: none !important;
  }
.errormsg{
  color: red;
    margin-left: 10px;
    position: relative;
    top: 10px;

}
  /* ion-select::part(placeholder) {
    font-weight: 400 !important;
    color: #FFFFFF !important;
    --placeholder-color: none !important;
    --placeholder-opacity: none !important; 
  } */

  .tfo-calendar-picker-input{
    /* width: 136% !important; */
    border: none !important;
    /* margin-left: -11px !important; */
  }
  .notes-line-nn {
    margin-left: 24px !important;
    margin-right: 32px !important;
  }
  /* .NoteTypeinput {
    margin-left: 32px;
  } */
  .attendeesSelect
  {
    margin-left:10px;
  }
.meetingdatentime {
  width: 125% !important;
}
.customIonSelect1 div.select-text
{
  position: relative;
  top: 3px;
    
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .sentiTitleRow ion-label {
      font-size: 15px !important;
    }
    .nxttimepickerCol {
      padding-left: 0px !important;
    }
  }
  
  @media (min-width: 1025px) and (-webkit-max-device-pixel-ratio: 1.75)  {
  .new-note-ion-item,
  .notes-line-desk {
    margin-right: 0px !important;
    margin-left: -5px !important;
    --padding-start: 0px !important;
  }
  .headerRow-desk {
    margin-left: 0px !important;
  }
  .txtAreaRowDesk {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .customIonSelect>div.select-text
{
  position: relative;
  top: 3px;
    
}
  .headerRow-desk ion-col>ion-row {
    margin-top: 16px !important;
  }
  .headerRow1-desk ion-col>ion-row {
    margin-top: 0px !important;
  }
  .NoteTypeinput1 {
    margin-top: 17px !important;
  } 

}
@media only screen and (min-width: 790px) {
  .alert-wrapper.sc-ion-alert-md {
    position:absolute;
    left:180px;
  }
}
@media only screen and (max-width: 575px) {
  .m-margin-rght16
  {
    margin-right:16px!important;
  }
  .m-margin-leftn5
  {
    margin-left:-5px;
  }
}

/* ion-datetime {
  color: #B99855;
} */
ion-datetime {
  --placeholder-color: #B99855;
  
}

&:not(.datetime-placeholder) {
  color: #B99855;
}
.picker-button
{
  color:#B99855!important;
}
.picker-opt-selected
{
  color:#B99855!important;
}