
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .labelssvg {
        left: 64px !important;
    }    
    .axessvg {
        left: 64px !important;

    }
}
.bar 
{
    padding-left: 60px;
    padding: 0 auto;
}
.sBar:hover {
    fill: brown;
}
.axessvg {
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    fill-opacity: 1.5;
}
.font12
{
    font-size: 11px!important;
}