@media (min-width: 1025px) and (-webkit-max-device-pixel-ratio: 1.75)  {
    .firstLeftDesk>ion-card-header,
    .firstLeftDeskNr>ion-card-header {
        font-size: 14px !important;
    }
    .firstLeftDesk>ion-card-header {
        font-size: 14px !important;
    }
    .mnote-det-content ion-row {
        margin-left: 180px !important;
        margin-right: 180px !important;
    }
}